import { Fragment } from 'react';

import { defaultMetaTags } from '../lib/constants';

import Head from "next/head";

import Header from '../components/header';
import Footer from '../components/footer';
import Sidebar from '../components/sidebar';
import SocialButtons from "../components/social";


export default function Layout({ children, title, metaTags}) {
    return (
        <Fragment>
          <Head>
            <title>{ title}</title>
          </ Head>

          <div className="inner">
            { children }
            <aside className="email-widget">
              <Sidebar />
            </aside>
            <style jsx>{`
                .inner {
                    display: flex;
                    flex-direction: row;
                    max-width: 1000px;
                    margin: 0 auto;
                    align-items: top;
                    justify-content: space-between;
                }

                .email-widget {
                    display: none;
                }
                @media screen and (min-width: 614px) {
                    .email-widget {
                        width: 35%;
                        display: block;
                    }
                }

            `}</style>
          </div>
        </ Fragment>
    );
}


Layout.defaultProps = {
    metaTags: defaultMetaTags
};
