import React, { useState, useEffect } from 'react'
import ClientOnlyPortal from './ClientOnlyPortal'


export default function Modal({ visible, setVisible }) {
    function modalDisappears(event) {
	setVisible(false);
    }
    return (
    <React.Fragment>
      {visible && (
        <ClientOnlyPortal selector="#modal">
              <div className="backdrop" onClick={modalDisappears}>
              <div className="modal">
	      <div className="close-modal-container">
	      <a href="#" onClick={event => setVisible(false)}aria-label="Close Account Info Modal Box">&times;</a>
	      </div>
              <h3>Thanks for Subscribing!</h3>
              <p>Look for our next newsletter in your inbox!
              </p>
            </div>
            <style jsx>{`
              :global(body) {
                overflow: hidden;
              }
              .backdrop {
                position: fixed;
                background-color: rgba(0, 0, 0, 0.05);
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .modal {
                background-color: white;
                padding: 1em;
                padding-top: .25em;
                position: fixed;
                bottom:15vw;
                right:0px;
                margin:0px
              }
              .close-modal-container {
                  display: flex;
                  justify-content: flex-end;
              }
            `}</style>
          </div>
        </ClientOnlyPortal>
      )}
    </React.Fragment>
  )
}
