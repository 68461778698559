import {
    FacebookShareButton,
    FacebookIcon,
    FacebookShareCount,
    TwitterShareButton,
    TwitterIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    EmailShareButton,
    EmailIcon,
} from "react-share";

import {
    useState,
    useRef,
    useEffect } from "react";


export default function SocialButtons({ shareUrl, title }) {
    // todo maybe I need to not hardcode this
    const [style_top, setStyleTop] = useState("0px");
    const [style_left, setStyleLeft] = useState("0px");
    var [displaySocial, setDisplaySocial] = useState("none");

    function handleScroll() {
	const topOfScreen = window.scrollY === 0;
	if (topOfScreen) {
	    const y = getInitialTop();
	    setStyleTop(`${y}px`);
	} else {
	    const middleViewport = getViewportMiddle();
	    const yoffset = window.scrollY;
	    setStyleTop(`${middleViewport}px`);
	}
    }


    function getViewportMiddle() {
	const viewportTop = window.scrollY;
	const viewportBottom = viewportTop + window.innerHeight;
	const middle = (viewportTop + viewportBottom) / 2.0 - 45;
	return middle;
    }

    function getInitialTop() {
	const aside = document.getElementById("social-aside");
	const position = aside.getBoundingClientRect();
	return position.y;
    }

    function getInitialLeft() {
	const aside = document.getElementById("social-aside");
	const position = aside.getBoundingClientRect();
	return position.x;
    }

    function debounce(fn, time) {
	let timeout;

	return function() {
	    const context = this;
	    const args = arguments;

	    window.clearTimeout(timeout);
	    timeout = window.setTimeout(() => fn.apply(context, args), time);
	};
    }

    useEffect(() =>{
	    setDisplaySocial("flex");
	    setStyleTop(() => getInitialTop);
	    setStyleLeft(() => getInitialLeft);
	    window.addEventListener('scroll', debounce(handleScroll, 100));
	    return (()=> {window.removeEventListener('scroll', handleScroll)});
    }, []);


    return (
        <React.Fragment>
          <ul className="social-share">
            <li>
                <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    className="button" 
                >
                <FacebookIcon
                  size={32}
                  round={false} />
              </FacebookShareButton>

              <FacebookShareCount
                  url={shareUrl}
                  className="count">
                  {count => count}
              </FacebookShareCount>
            </li>

            <li>
              <TwitterShareButton
                url={shareUrl}
                title={title}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </li>

            <li>
              <RedditShareButton
                url={shareUrl}
                title={title}
                windowWidth={660}
                windowHeight={460}
              >
                <RedditIcon size={32} round />
              </RedditShareButton>
            </li>

            <li>
              <EmailShareButton
                url={shareUrl}
                subject={title}
                body="body"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </li>
          </ul>
          <style jsx>{`
             ul {
                 display: ${displaySocial};
                 flex-direction: row;
                 list-style-type: none;
                 padding: 0;
                 justify-content: space-between;
                 top: ${style_top};
                 left: ${style_left};
                 position: absolute;
             }
             @media screen and (min-device-width: 614px) { 
             ul {
                  list-style-type: none;
                  margin-top: 0;
                  padding: 0;
                  padding-right: 2rem;
                  display: ${displaySocial};
                  flex-direction: column;
                  padding: 0;
                  top: ${style_top};
              }
              }
          `}</style>
        </React.Fragment>
    );
}
