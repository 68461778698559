import { useState, useEffect, useRef } from "react";
const axios = require("axios");
const fetch = require("isomorphic-fetch");

import Modal from "../components/modal";


export default function Sidebar() {
    return (
        <SidebarEmail>
        </SidebarEmail>
    );
}


function SidebarEmail() {
    const emailRef = useRef();
    const [visible, setVisible] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
	const email = emailRef.current.value;
	const request_url = "http://localhost:3000/api/email/recipients";
	fetch(request_url, {
	    method: 'PUT',
	    body: JSON.stringify({email: email}),
	    headers: {
		"Content-Type": "application/json"
	    },
	})
	    .then(resp => console.log(resp))
	    .catch(
		err => console.log(err)
	    );
	setVisible(true);

    };

    return (
        <div className="container-sidebar-email">
          <h2>Inbox Zero is Overrated</h2>
          <ul>
            <li>Negotiate a stellar comp package</li>
            <li>Understand compounding and leverage to Pareto your finances</li>
            <li>Achieve financial freedom without counting beans</li>
          </ul>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              ref={emailRef}
              placeholder="&#x1F44B; Your Email Here">
            </input>
            <button type="submit">READ IT</button>
            </form>
	    <Modal visible={ visible } setVisible={ setVisible } />
          <style jsx>{`
                .container-sidebar-email {
                    background-color: #DDEAF2;
                    padding: 2em 1em;
                }
                ul {
                    list-style: none;
                    padding-inline-start: 0;
                    font-family: "Inter";
                    font-size: 20px;
                }
                li {
                    font-size: 13px;
                    margin-bottom: 1rem;
                }
                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    font-family: "Calibre";
                    text-align: center;
                }
                button {
                    border: none;
                    background-color: black;
                    color: white;
                    padding: .75em 1em;
                }
                form {
                    display: flex;
                    flex-direction: column;
                }
                input {
                    border-style: solid;
                    border-color: black;
                    border-width: 1px;
                    padding: .75em 1em;
                }
                `}</style>

        </div>
    );
}
